<template>
  <div class="csn-bonus-cancel">
    Bonus Cancel: WIP
  </div>
</template>
<script>
import { BONUS_CANCEL } from '@/constants'

export default {
  name: BONUS_CANCEL,
}
</script>
